import {downloadUrl} from "@/api/index.js"
import store from '../store'
export default {
	
	//模糊搜索查询数据
	getSearch(data,keyWord){
		let arr = []
		for(let i=0;i<data.length;i++){
			if(data[i].DisplayName.indexOf(keyWord)>=0){
			    arr.push(data[i]);
			}
		}
		return arr
	},
	
	//获取token
	getToken(){
		return localStorage.getItem('gameToken')
	},
	// 下载方法
	getDownload(data){
		downloadUrl(data.slug).then(res=>{
			data.downloadUrl = res.url
			store.commit('downloadInfo',data)
		})
	},
	speedChange(limit){
	    var size = "";
	    if(limit < 0.1 * 1024){                            //小于0.1KB，则转化成B
	        size = limit.toFixed(2) + "B/s"
	    }else if(limit < 0.1 * 1024 * 1024){            //小于0.1MB，则转化成KB
	        size = (limit/1024).toFixed(2) + "KB/s"
	    }else if(limit < 0.1 * 1024 * 1024 * 1024){        //小于0.1GB，则转化成MB
	        size = (limit/(1024 * 1024)).toFixed(2) + "MB/s"
	    }else{                                            //其他转化成GB
	        size = (limit/(1024 * 1024 * 1024)).toFixed(2) + "GB/s"
	    }
	 
	    var sizeStr = size + "";                        //转成字符串
	    var index = sizeStr.indexOf(".");                    //获取小数点处的索引
	    var dou = sizeStr.substr(index + 1 ,2)            //获取小数点后两位的值
	    if(dou == "00"){                                //判断后两位是否为00，如果是则删除00               
	        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
	    }
	    return size;
	},
	getfilesize(size) {
		if (!size){
			return "";
		}
		var num = 1024.00; //byte
						 
		if (size < num)
			return size + "B";
		if (size < Math.pow(num, 2))
			return (size / num).toFixed(2) + "K"; //kb
		if (size < Math.pow(num, 3))
			return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
		if (size < Math.pow(num, 4))
			return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
		return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T		
	},
	differDay(time1, time2) {
		let d1 = new Date(time1) * 1
		let d2 = new Date(time2) * 1
		let time = d2 - d1
		return time / (1000 * 60 * 60 * 24)
	},
	//获取当前时间
	formatTime(type) {
		var date = new Date(new Date().getTime());
		var YY = date.getFullYear() + '-';
		var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
		var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
		var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
		var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
		var ss = ':' + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
		if (type == 1) {
			return DD
		} else if (type == 2) {
			return YY + MM + DD + " " + hh + mm;
		} else {
			return YY + MM + DD + " " + hh + mm + ss;
		}
	},
}
