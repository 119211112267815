import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  userInfo:{},
	  vipInfo:{},
	  isLogin:false,
	  softwareIds:'',
	  keywordVal:'',
	  downloadData:{}
  },
  mutations: {
	  //保存用户登录信息
	  saveUserInfo(state,userInfo){
		  state.userInfo = userInfo;
	  },
	  //保存用户vip信息
	  saveVipInfo(state,vipInfo){
	  	  state.vipInfo = vipInfo;
	  },
	  //保存用户登录状态
	  saveIsLogin(state,isLogin){
	  	  state.isLogin = isLogin;
	  },
	  //用户点击跳转软件详情页面
	  savePassId(state,id){
	  	state.softwareIds = id
	  },
	  // 用户搜索框传值到搜索页面
	  saveKeyword(state,val){
		  state.keywordVal = val
	  },
	  // 下载
	  downloadInfo(state,data){
		 state.downloadData = data
	  },
  },
  actions: {
  },
  modules: {
  }
})
