<template>
	<div class="sidebar-main">
		<div class="sidebar-nav-item">
			<img src="../../assets/sidebar/app-logo.png" class="sidebar-nav-logo"/>
			<div class="sidebar-nav-info">
				<img src="../../assets/sidebar/app-logo-text.png" class="sidebar-nav-logotext"/>
				<div class="sidebar-logo-text">一键安装 轻松便捷</div>
			</div>
		</div>
		<div class="sidebar-main-item gestures" v-for="(value,index) in sidebarList" :key="index" 
		:class="{'sidebar-items':index==sidebarListIndex,'intervals':value.type==1}" @click="changeMnue(value,index)">
			<img :src="index==sidebarListIndex?value.imgs:value.img" class="sidebar-main-item-img"/>
			<div class="sidebar-main-item-text">{{value.text}}</div>
			<div class="sidebar-item-line" v-if="index==sidebarListIndex"></div>
		</div>
	</div>
</template>

<script>

	export default {
		data(){
			return{
				sidebarList:[
					{text:'精品推荐',img:require('../../assets/sidebar/boutique-icon.png'),imgs:require('../../assets/sidebar/boutiques-icon.png'),type:0,path:'home'},
					{text:'装机必备',img:require('../../assets/sidebar/necessary-icon.png'),imgs:require('../../assets/sidebar/necessarys-icon.png'),type:0,path:'necessary'},
					{text:'办公软件',img:require('../../assets/sidebar/work-icon.png'),imgs:require('../../assets/sidebar/works-icon.png'),type:0,path:'work'},
					{text:'聊天社交',img:require('../../assets/sidebar/education-icon.png'),imgs:require('../../assets/sidebar/educations-icon.png'),type:0,path:'education'},
					{text:'游戏娱乐',img:require('../../assets/sidebar/game-icon.png'),imgs:require('../../assets/sidebar/games-icon.png'),type:0,path:'game'},
					{text:'全部分类',img:require('../../assets/sidebar/classify-icon.png'),imgs:require('../../assets/sidebar/classifys-icon.png'),type:0,path:'classify'},
					{text:'软件升级',img:require('../../assets/sidebar/upgrade-icon.png'),imgs:require('../../assets/sidebar/upgrades-icon.png'),type:1,path:'upgrade'},
					{text:'软件卸载',img:require('../../assets/sidebar/uninstall-icon.png'),imgs:require('../../assets/sidebar/uninstalls-icon.png'),type:0,path:'uninstall'},
				],
				sidebarListIndex:0
			}
		},
		watch:{
			//监听路由
			$route:{
				handler(to, from) {
					if(to){
						console.log(to)
						let index = this.sidebarList.findIndex(item=>item.path == to.name)
						console.log(index)
						if(index>-1){
							this.sidebarListIndex = index
						}
					}
				},
				deep: true,
				immediate: true
			},
		},
		created() {
			// let menuData = localStorage.getItem('menuIndex')
			// if(menuData){
			// 	this.sidebarListIndex = menuData
			// }
		},
		
		methods:{
			changeMnue(data,index){
				if(this.sidebarListIndex!=index){
					this.sidebarListIndex=index
					localStorage.setItem('menuIndex', index)
					this.$router.push({ name: data.path});
				}
			}
		}
	}
</script>

<style lang="scss">
	.sidebar-main{
		position: fixed;
		top: 0px;
		left: 0;
		bottom: 0;
		background-color: #F4F4F4;
		width: 170px;
	}
	.sidebar-nav-item{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 35px 0px;
		.sidebar-nav-logo{
			width: 30px;
			height: 32px;
		}
		.sidebar-nav-info{
			margin-left: 8px;
			.sidebar-nav-logotext{
				width: 94px;
				height: 21px;
			}
			.sidebar-logo-text{
				color: #9E9E9E;
				font-size: 12px;
				// margin-top: 3px;
			}
		}
	}
	
	.sidebar-main-item{
		height: 58px;
		position: relative;
		display: flex;
		align-items: center;
		padding-left: 40px;
		.sidebar-main-item-img{
			width: 20px;
			height: 20px;
		}
		.sidebar-main-item-text{
			color: #4D5571;
			font-size: 14px;
			margin-left: 8px;
		}
		.sidebar-item-line{
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 4px;
			border-radius: 0px 6px 6px 0px;
			background-color: #00B386;
		}
	}
	.sidebar-items{
		background-color: #ffffff;
		.sidebar-main-item-text{
			color: #00B386;
		}
	}
	.intervals{
		margin-top: 50px;
	}
</style>
