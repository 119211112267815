import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index'
// ../layout/index
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
  	component: Layout,
  	children: [
  	  {
  	    path: '/',
  	    component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
  	    name: 'home',
  	  }
  	]
  },
  {
    path: '/necessary/index',
    name: 'necessary',
  	component: Layout,
  	children: [
  	  {
  	    path: '/necessary/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/necessary/index.vue'),
  	    name: 'necessary',
  	  }
  	]
  },
  {
    path: '/classify/index',
    name: 'classify',
  	component: Layout,
  	children: [
  	  {
  	    path: '/classify/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/classify/index.vue'),
  	    name: 'classify',
  	  }
  	]
  },
  {
    path: '/softwareDetails/index',
    name: 'softwareDetails',
  	component: Layout,
  	children: [
  	  {
  	    path: '/softwareDetails/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/softwareDetails/index.vue'),
  	    name: 'softwareDetails',
  	  }
  	]
  },
  {
    path: '/search/index',
    name: 'search',
  	component: Layout,
  	children: [
  	  {
  	    path: '/search/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/search/index.vue'),
  	    name: 'search',
  	  }
  	]
  },
  {
    path: '/upgrade/index',
    name: 'upgrade',
  	component: Layout,
  	children: [
  	  {
  	    path: '/upgrade/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/upgrade/index.vue'),
  	    name: 'upgrade',
  	  }
  	]
  },
  {
    path: '/uninstall/index',
    name: 'uninstall',
  	component: Layout,
  	children: [
  	  {
  	    path: '/uninstall/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/uninstall/index.vue'),
  	    name: 'uninstall',
  	  }
  	]
  },
  {
    path: '/work/index',
    name: 'work',
  	component: Layout,
  	children: [
  	  {
  	    path: '/work/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/work/index.vue'),
  	    name: 'work',
  	  }
  	]
  },
  {
    path: '/education/index',
    name: 'education',
  	component: Layout,
  	children: [
  	  {
  	    path: '/education/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/education/index.vue'),
  	    name: 'education',
  	  }
  	]
  },
  {
    path: '/game/index',
    name: 'game',
  	component: Layout,
  	children: [
  	  {
  	    path: '/game/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/game/index.vue'),
  	    name: 'game',
  	  }
  	]
  },
  
  // {
  //   path: '/detection/index',
  //   name: 'detection',
  // 	component: Layout,
  // 	children: [
  // 	  {
  // 	    path: '/detection/index',
  // 	    component: () => import(/* webpackChunkName: "about" */ '../views/detection/index.vue'),
  // 	    name: 'detection',
  // 	  }
  // 	]
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
