<template>
	<div>
		<div class="fixed-header">
			<div class="fixed-header-nav">
				<img src="../../assets/navbar/default-back.png" class="back-icon gestures" v-if="isBack&&!narrowSel" @mouseover="mouseOverGame('narrowSel',true)"  @mouseleave="mouseOverGame('narrowSel',false)"/>
				<img src="../../assets/navbar/yes-back-icon.png" class="back-icon gestures" @click="changeBack" v-if="isBack&&narrowSel" @mouseover="mouseOverGame('narrowSel',true)"  @mouseleave="mouseOverGame('narrowSel',false)"/>
				<img src="../../assets/navbar/no-back-icon.png" class="back-icon gestures no-click" v-if="!isBack"/>
				<div style="position: relative;">
					<div class="search-nav-item" @click="getFocus(true)" ref="boxs">
						<!-- <div class="search-nav-tel">抖音</div> -->
						<input placeholder="记录美好生活" class="search-nav-val" v-model="searchVal" @focus="getFocus(true)" @keyup.enter="searchsGame"/>
						<img src="../../assets/navbar/search-nav-icon.png" class="search-nav-img gestures" @click.stop="searchsGame"/>
						
						<div class="search-clue-main" v-if="isFocus">
							<div class="search-clue-left">
								<div class="search-clue-today">今日热搜</div>
								<div class="search-clue-list" v-for="(value,index) in todayList" :key="index" @click="changeToday(value.application)">
									<div class="search-clue-nums" :class="[index<3?'ranking-rank-'+index:'']">{{index+1}}</div>
									<div class="search-clue-tels gestures">{{value.application.name}}</div>
									<!-- <div class="search-clue-tags heat-tag" v-if="value.heat">热</div> -->
									<!-- <div class="search-clue-tags new-tag" v-if="value.new">新</div> -->
								</div>
							</div>
							<div class="search-clue-ret">
								<div class="clue-ret-tels">大家都在用</div>
								<el-row >
								  <el-col :span="12" v-for="(value,index) in allUseList" :key="index">
									  <div class="clue-ret-list">
										  <img :src="value.application.cover" class="clue-ret-list-img"/>
										  <div class="ret-list-infos">
											  <div class="ret-list-name ellipsis-text gestures" @click="changeToday(value.application)">{{value.application.name}}</div>
											  <div class="ret-list-btns gestures" @click="erect(value.application)" v-if="value.installType == 0">安装</div>
											  <div class="ret-list-btns gestures" @click="erect(value.application)" v-if="value.installType == 1">升级</div>
											  <div class="ret-list-btns gestures" @click="openSoftware(value.application)" v-if="value.installType == 2">打开</div>
										  </div>
									  </div>
								  </el-col>
								</el-row>
							</div>
						</div>
						
					</div>
					<!-- 搜索结果 -->
					<div class="search-result-main"  v-infinite-scroll="searchLoad" style="overflow:auto" v-show="searchShow">
						<div v-for="(value,index) in searchData" :key="index">
							<div class="search-result-item" v-if="index < 2" @click="searchDetails(value)">
								<img :src="value.cover" class="search-result-img gestures"/>
								<div class="search-result-info">
									<div class="search-result-name gestures">{{value.name}}</div>
									<div class="search-result-num">{{value.downloadCount}}</div>
								</div>
								<div class="search-result-btn gestures" @click.stop="installs(value)">安装</div>
							</div>
							<div class="search-result-tel gestures" v-if="index > 1" @click="searchDetails(value)">{{value.name}}</div>
						</div>
						
					</div>
				</div>
				
				<div class="download-main" ref="boxs1" >
					<img v-if="!isDownload" src="../../assets/navbar/download-nav-icon.png" class="download-nav-img gestures" @click="downloadShow = true"/>
					<img v-if="isDownload" src="../../assets/navbar/downloading.gif" class="download-nav-img gestures" @click="downloadShow = true"/>
					<div class="download-manage" v-show="downloadShow" :style="{'width':widths}">
						<div class="download-nav">
							<div class="download-nav-tel">下载管理</div>
							<div class="download-nav-ret">
								<div class="gestures">设置</div>
								<img src="../../assets/navbar/xaiz-closes.png" class="nav-ret-close gestures" @click="downloadShow = false"/>
							</div>
						</div>
						<div class="download-roll" v-if="downloadArray.length > 0">
							<div class="download-list" v-for="(value,index) in downloadArray" :key="index">
								<div class="download-content">
									<img :src="value.cover" class="download-content-img"/>
									<div class="download-content-info">
										<div class="download-content-name">{{value.name}}</div>
										<div class="download-content-text" v-if="value.installStatus < 3">{{value.progress}}%<span class="speed">{{value.speed}}</span></div>
										<div class="download-content-text" v-if="value.installStatus == 3">正在安装...</div>
										<div class="download-content-text" v-if="value.installStatus == 4">{{value.size}}</div>
									</div>
									<div class="download-content-ret">
										<div class="opens-btns gestures" v-if="value.installStatus == 4&&value.btnStatus == 0" @click="erectData(value,index)">安装</div>
										<div class="opens-btns gestures" v-if="value.installStatus == 4&&value.btnStatus == 1" @click="erectData(value,index)">升级</div>
										<div class="opens-btns gestures" v-if="value.installStatus == 4&&value.btnStatus == 2" @click="openSoftware(value)">打开</div>
										<img src="../../assets/navbar/suspend-icon.png" @click="suspend(value,index)" class="gestures" v-if="value.installStatus == 0"/>
										<img src="../../assets/navbar/restart-icon.png" @click="play(value,index)" class="gestures" v-if="value.installStatus == 1"/>
										<img src="../../assets/navbar/cancel-icon.png" @click="cease(value,index)" style="margin-left: 10px;" class="gestures"/>
									</div>
								</div>
								
								<div class="download-schedule" :style="{'width':`${value.progress}%`}"></div>
							</div>
						</div>
						<div class="empty-item" v-if="downloadArray.length == 0">
							<img src="../../assets/navbar/download-empty.png">
						</div>
						<div class="download-directory gestures" @click="openDirectory">打开下载目录</div>
					</div>
				</div>
				
				
				<div class="fixed-header-menu" ref="boxs2">
					<img src="../../assets/navbar/header-menu.png" v-if="!showMenu" class="header-menu-img gestures" @click="changeMenu(true)"/>
					<img src="../../assets/navbar/menu-sel-icon.png" v-if="showMenu" class="header-menu-img gestures" @click="changeMenu(false)"/>
					<img :src="showMian?require('../../assets/navbar/header-mins-sel.png'):require('../../assets/navbar/header-mins.png')" 
					class="header-menu-img gestures" @click="minimized" @mouseover="mouseOverGame('showMian',true)"  @mouseleave="mouseOverGame('showMian',false)"/>
					<img :src="showClose?require('../../assets/navbar/header-close-sel.png'):require('../../assets/navbar/header-close.png')" 
					class="header-menu-img gestures" @click="close" @mouseover="mouseOverGame('showClose',true)"  @mouseleave="mouseOverGame('showClose',false)"/>
					<div class="fixed-menu-list" v-if="showMenu" >
						<div class="fixed-menu-item gestures" @click='setupShow'>
							<img src="../../assets/navbar/set-icons.png" class="fixed-menu-img"/>
							<div class="fixed-menu-text">设置中心</div>
						</div>
						<div class="fixed-menu-item gestures">
							<img src="../../assets/navbar/guanfang-icons.png" class="fixed-menu-img"/>
							<div class="fixed-menu-text">官方网站</div>
						</div>
						<div class="fixed-menu-item gestures">
							<img src="../../assets/navbar/guanyu-icons.png" class="fixed-menu-img"/>
							<div class="fixed-menu-text">关于我们</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		
		<!-- 设置中心 -->
		<el-dialog
		  title=""
		  :visible.sync="dialogVisible"
		  width="660px"
		  :close-on-click-modal="false"
		  :close-on-press-escape="false"
		  :show-close="false">
		  <div class="focus-cards">
			  <div class="focus-card-left">
				  <div class="focus-left-top">
					  <img src="../../assets/navbar/set-logo-icon.png" class="focus-left-logo"/>
					  <div class="focus-left-name">设置中心</div>
				  </div>
				  <div class="focus-tab-list">
					  <div class="focus-tab-item gestures" @click="changeTabs(index)" :class="{'focus-tabs':index==focusTabIndex}" 
					  v-for="(value,index) in focusTab" :key="index">{{value.text}}</div>
				  </div>
			  </div>
			  
			  <div class="focus-card-ret">
				  <div class="focus-ret-top">
					  <img src="../../assets/navbar/set-close-icon.png" class="focus-ret-img gestures" @click='dialogVisible = false'/>
				  </div>
				  <div class="focus-ret-main" v-if="focusTabIndex==0">
					  <div class='focus-ret-tel'>下载设置</div>
					  <div class="download-tips">将软件下载到此目录下<span class="download-open gestures" @click="openDownloadDir">打开目录</span></div>
					  <div class="download-items">
						  <div class="download-item-left">{{downloadDir}}</div>
						  <div class="download-item-ret gestures" @click="selDownloadDir">选择目录</div>
					  </div>
					  <div class='free-space'>可用空间：{{freeBytes}}</div>
					  <div class='focus-ret-tel' style="margin-top: 34px;">下载时关闭软件管家</div>
					  <div class="download-close-list gestures" v-for="(value,index) in downloadList" :key="index" @click="changeDown(index,value.type)">
						  <img src="../../assets/navbar/set-no-sel.png" class="download-set-img" v-if="downloadListIndex!=index"/>
						  <img src="../../assets/navbar/set-yes-sel.png" class="download-set-img" v-if="downloadListIndex==index"/>
						  <div class="download-set-text">{{value.text}}</div>
					  </div>
				  </div>
				  
				  <div class="focus-ret-main" v-if="focusTabIndex==1">
					  <div class='focus-ret-tel'>软件升级提醒</div>
					  <div class="upgrade-items">
						  <img src="../../assets/img/no-select.png" class="focus-sel-img gestures" v-if="!isUpgrade" @click="changeSel('isUpgrade',true)"/>
						  <img src="../../assets/img/yes-select.png" class="focus-sel-img gestures" v-if="isUpgrade" @click="changeSel('isUpgrade',false)"/>
						  <div class="upgrade-item-info">
							  <div class="upgrade-info-name">根据软件更新频率，智能提醒升级（建议开启）</div>
							  <div class="upgrade-info-text">建议您开启升级弹窗，避免错过大牌软件更新</div>
						  </div>
					  </div>
					  <div class='focus-ret-tel' style="margin-top: 30px;">卸载设置</div>
					  <div class="upgrade-items">
					  		<img src="../../assets/img/no-select.png" class="focus-sel-img gestures" v-if="!isUnload" @click="changeSel('isUnload',true)"/>
					  		<img src="../../assets/img/yes-select.png" class="focus-sel-img gestures" v-if="isUnload" @click="changeSel('isUnload',false)"/>
					  		<div class="upgrade-item-info">
					  			<div class="upgrade-info-name">软件快捷方式右键添加“强力卸载此软件”选项</div>
					  			<div class="upgrade-info-text">开启后，将在软件图标右键添加“强力卸载此软件”选项，一键卸载软件</div>
					  		</div>
					  </div>
				  </div>
				  
				  <div class="focus-ret-main" v-if="focusTabIndex==2">
					  <div class='focus-ret-tel'>安装包清理</div>
					  <div class="download-close-list gestures" v-for="(value,index) in installList" :key="index" @click="changeInst(index)">
					  		<img src="../../assets/navbar/set-no-sel.png" class="download-set-img" v-if="instalListIndex!=index"/>
					  		<img src="../../assets/navbar/set-yes-sel.png" class="download-set-img" v-if="instalListIndex==index"/>
					  		<div class="download-set-text">{{value.text}}</div>
					  </div>
					  <div class='focus-ret-tel' style="margin-top: 30px;">未知文件设置</div>
					  <div class="upgrade-items">
					  		<img src="../../assets/img/no-select.png" class="focus-sel-img gestures" v-if="!isUnknown" @click="changeSel('isUnknown',true)"/>
					  		<img src="../../assets/img/yes-select.png" class="focus-sel-img gestures" v-if="isUnknown" @click="changeSel('isUnknown',false)"/>
					  		<div class="upgrade-item-info">
					  			<div class="upgrade-info-name">当您打开未知文件时，为您推荐合适软件</div>
					  			<div class="upgrade-info-text">开启后，打开未知文件将为您推荐打开的软件</div>
					  		</div>
					  </div>
				  </div>
				  
				  <div class="focus-boom-item">
					  <div class="focus-boom-btn gestures" @click='dialogVisible = false'>确定</div>
					  <div class="focus-boom-btn-close focus-boom-btn gestures" @click='dialogVisible = false'>取消</div>
				  </div>
			  </div>
		  </div>
		</el-dialog>
		
		<el-dialog
		  title=""
		  :visible.sync="recommendShow"
		  width="660px"
		  :close-on-click-modal="false"
		  :close-on-press-escape="false"
		  :show-close="false">
		  <div class="recommend-main">
			<div class="recommend-head">
				<div class="recommend-head-text gestures" @click="recentOut">近期不再弹出</div>
				<img src="../../assets/navbar/recommend-closes.png" class="recommend-head-img gestures" @click="recommendShow = false"/>
			</div>
			<div class="recommend-tel">热门软件推荐</div>
			<div class="grid-main">
				<el-row :gutter="20" >
				  <el-col :span="6" v-for="(value,index) in recommendArray" :key="index">
					  <div class="recommend-grid">
						  <div style="height: 20px;"></div>
						  <img :src="value.cover" class="recommend-list-img"/>
						  <div class="recommend-list-name">{{value.name}}</div>
						  <img src="../../assets/img/no-select.png" class="select-imgs gestures" v-show="!value.select" @click="tabSelelct(true,index)"/>
						  <img src="../../assets/img/yes-select.png" class="select-imgs gestures" v-show="value.select" @click="tabSelelct(false,index)"/>
					  </div>
				  </el-col>
				</el-row>
			</div>
			<div class="recommend-boom-item">
				<div class="recommend-install gestures">一键安装</div>
			</div>
		  </div>
		</el-dialog>
		
	</div>
</template>

<script>
import {moduleData,searchFun,downloadUrl,recommendData} from "@/api/index.js"
import { mapState } from 'vuex';
import config from "@/utils/config.js"
import qs from 'qs'
	export default {
		data(){
			return{
				showMenu:false,
				dialogVisible:false,
				focusTab:[
					{text:'下载设置'},
					{text:'安装升级'},
					{text:'其他设置'}
				],
				focusTabIndex:0,
				downloadList:[
					{text:'最小化软件管家',type:false},
					{text:'停止下载安装任务，退出软件管家',type:true}
				],
				downloadListIndex:0,
				isUpgrade:false,
				isUnload:false,
				installList:[
					{text:'下载完成7天后，自动删除安装包'},
					{text:'安装完成后，自动删除安装包'},
					{text:'手动删除'}
				],
				instalListIndex:0,
				isUnknown:false,
				todayList:[],
				isFocus:false,
				isBack:false,
				narrowSel:false,
				allUseList:[],
				searchVal:'',
				downloadShow:false,
				downloadArray:[],
				showClose:false,
				showMian:false,
				searchShow:false,
				searchData:[],
				freeBytes:'',
				downloadDir:'',
				widths:'296px',
				installArray:[],
				isDownload:false,
				recommendShow:false,
				idsList:['6120','13407','L103025','8033'],
				recommendArray:[]
			}
		},
		watch:{
			//监听路由
			$route:{
				handler(to, from) {
					if(to){
						this.isBack = to.name == 'softwareDetails' || to.name == 'search'
					}
				},
				deep: true,
				immediate: true
			},
			'$store.state.downloadData':{
				handler(val) {
					if(val){
						val.progress = ''
						val.speed = ''
						val.installStatus = 0
						val.btnStatus = 0
						// console.log(val)
						let indexs = this.downloadArray.findIndex(item=>item.id == val.id)
						if(indexs<0){
							this.downloadArray.unshift(val)
							window.win_install_program(val.id,val.downloadUrl)
						}else{
							if(this.downloadArray[indexs].installStatus == 4 && this.downloadArray[indexs].btnStatus < 2){
								// window.win_install_program(val.id,val.downloadUrl)
								this.erectData(this.downloadArray[indexs],indexs)
							}
						}
						this.downloadShow = true
						this.isDownload = true
					}
				}
			},
			searchVal(e){
				if(e == ''){
					this.isFocus = true
					this.searchShow = false
				}else{
					// this.isFocus = false
					setTimeout(() => {
						this.realSearch(e)
					},1000); 
				}
			},
		},
		created() {
			this.renewStatus()
			if(navigator.userAgent.indexOf("123soft") != -1){
				let data = window.win_program_list()
				this.installArray = JSON.parse(data)
				this.freeBytes = config.getfilesize(window.win_free_bytes())
				localStorage.setItem('installData',data)
			}
			this.widths = document.documentElement.clientWidth < 960 ? '250px' : '296px'
			this.hotSearch()
			this.allUseData()
			let datas = localStorage.getItem('downloadData')
			let downloadIndex = localStorage.getItem('downloadIndex')
			if(datas){
				this.handleDown(JSON.parse(datas))
			}
			if(downloadIndex){
				this.downloadListIndex = downloadIndex
			}
			this.recommendFun()
			
		},
		computed:{
			// isDownload(){
			// 	let result = false
			// 	let array = this.downloadArray.filter(item=>item.installStatus == 0)
			// 	if(array.length > 0){
			// 		result = true
			// 	}
			// 	console.log(array)
			// 	console.log(result)
			// 	return result
			// },
		    // ...mapState(['userInfo','vipInfo']),
		},
		mounted(){
			
			document.addEventListener("click", (e) => {
				// console.log(e)
			    if (!this.$refs.boxs.contains(e.target)){
					this.isFocus = false;
					this.searchShow = false;
					window.win_leave_search_box()
				}
				if (!this.$refs.boxs1.contains(e.target)){
					this.downloadShow = false;
				}
				if (!this.$refs.boxs2.contains(e.target)){
					this.showMenu = false;
				}
				
			});
			window.installProgress = this.installProgress; 
			window.downloadSpeed = this.downloadSpeed; 
			window.installFailed = this.installFailed;
			window.installComplete = this.installComplete; 
			window.openProgramFailed = this.openProgramFailed
			window.setDownloadDir = this.setDownloadDir
		},
		methods:{
			allUpgrade(){
				let selList = this.recommendArray.filter(item=>item.select)
				if(selList.length == 0){
					return false
				}
				selList.forEach(item=>{
					config.getDownload(item)
				})
			},
			recentOut(){
				localStorage.setItem('time',config.formatTime())
				this.recommendShow = false
			},
			tabSelelct(type,index){
				this.recommendArray[index].select = type
			},
			recommendFun(){
				let index = Math.floor(Math.random() * 4)
				recommendData(this.idsList[index]).then(res=>{
					// console.log(res)
					res.list.forEach(item=>{
						item.select = false
					})
					this.recommendArray = res.list
					let timeData = localStorage.getItem('time')
					if(timeData){
						let days = config.differDay(timeData,config.formatTime())
						if(days>=5){
							setTimeout(() => {
								this.recommendShow = true
							},1500);
						}
					}else{
						setTimeout(() => {
							this.recommendShow = true
						},1500);
					}
					
				})
			},
			erectData(data,index){
				downloadUrl(data.slug).then(res=>{
					this.downloadArray[index].progress = ''
					this.downloadArray[index].speed = ''
					this.downloadArray[index].installStatus = 0
					this.downloadArray[index].btnStatus = 0
					this.isDownload = true
					window.win_install_program(data.id,res.url)
				})
			},
			// 处理下载过的数据
			handleDown(data){
				let list = data
				let prm = {
					keyword:'',
					page:1,
					page_size:10
				}
				list.forEach(item=>{
					item.progress = 0
					let array = config.getSearch(this.installArray,item.name)
					if(array.length > 0){
						prm.keyword = item.name
						searchFun(qs.stringify(prm)).then(res=>{
							// console.log(res)
							if(res.list.length > 0){
								if(res.list[0].version != array[0].Version){
									item.btnStatus = 1
								}else{
									item.btnStatus = 2
								}
							}
						})
					}else{
						item.btnStatus = 0
					}
				})
				this.downloadArray = list
			},
			openSoftware(data){
				window.win_open_program(data.name)
			},
			setupShow(){
				this.downloadDir = window.win_get_download_dir()
				this.dialogVisible = true
				// console.log()
				
			},
			setDownloadDir(){
				this.setupShow()
			},
			openDownloadDir(){
				window.win_open_download_dir()
			},
			selDownloadDir(){
				window.win_set_download_dir()
				
			},
			installs(data){
				console.log(data)
			},
			searchDetails(data){
				this.searchShow = false
				this.$router.push({ name: 'softwareDetails',query:{id:data.slug}});
			},
			openProgramFailed(){
				this.$notify.error({
				    title: '错误',
				    message: '打开软件失败,请稍后重试!'
				});
			},
			// 搜索
			realSearch(keyword){
				let prm = {
					keyword:keyword,
					page:1,
					page_size:10
				}
				searchFun(qs.stringify(prm)).then(res=>{
					console.log(res)
					if(res.list.length > 0){
						this.isFocus = false
						this.searchShow = true
						this.searchData = res.list
						
					}
					
					// this.isLoad = true
					// res.list.forEach(item=>{
					// 	item.scoreVal = item.score / 100
					// })
					// this.dataList = res.list
					// this.total = res.meta.pagination.total
				})
			},
			searchLoad(){
				console.log('见刘拉阿里')
			},
			suspend(data,index){
				this.downloadArray[index].installStatus = 1
				window.win_pause_download(data.id)
				this.renewStatus()
				this.$forceUpdate()
			},
			play(data,index){
				this.downloadArray[index].installStatus = 0
				window.win_continue_download(data.id)
				this.renewStatus()
				this.$forceUpdate()
			},
			cease(data,index){
				window.win_stop_download(data.id)
				this.downloadArray.splice(index,1)
				this.renewStatus()
				if(this.downloadArray.length > 0){
					this.setStorage()
				}else{
					// console.log(this.downloadArray.length)
					// localStorage.removeItem('downloadData')
					localStorage.removeItem('downloadData')
				}
				this.$forceUpdate()
			},
			openDirectory(){
				window.win_open_download_dir()
			},
			// 下载安装进度
			installProgress(id,data){
				// console.log(id,data)
				let index = this.downloadArray.findIndex(item=>item.id == id)
				if(index>-1){
					this.downloadArray[index].progress = data
					if(data == 100){
						this.downloadArray[index].installStatus = 3
					}
				}
				this.renewStatus()
				this.$forceUpdate()
			},
			// 下载完成
			installComplete(id,data){
				let index = this.downloadArray.findIndex(item=>item.id == id)
				if(index>-1){
					this.downloadArray[index].installStatus = 4
					this.downloadArray[index].btnStatus = 2
					this.$forceUpdate()
					this.renewStatus()
				}
				this.setStorage()
			},
			// 设置缓存
			setStorage(){
				let array = this.downloadArray.filter(item=>item.installStatus == 4)
				if(array.length > 0){
					localStorage.setItem('downloadData',JSON.stringify(array))
				}
			},
			// 下载网速
			downloadSpeed(id,data){
				let index = this.downloadArray.findIndex(item=>item.id == id)
				if(index>-1){
					this.downloadArray[index].speed = config.speedChange(data) 
					this.$forceUpdate()
				}
			},
			renewStatus(){
				let array = this.downloadArray.filter(item=>item.installStatus == 0)
				this.isDownload = array.length > 0 ? true : false
				
			},
			// 是否安装失败
			installFailed(id,data){
				
			},
			minimized(){
				window.win_minimized()
			},
			close(){
				window.win_close()
			},
			hotSearch(){
				moduleData(23).then(res=>{
					this.todayList = res.list
				})
			},
			allUseData(){
				moduleData(24).then(res=>{
					res.list.forEach(item=>{
						item.installType = 0
						let array = config.getSearch(this.installArray, item.application.name)
						if(array.length > 0){
							if(item.application.version != array[0].Version){
								item.installType = 1
							}else{
								item.installType = 2
							}
						}
					})
					this.allUseList = res.list
				})
			},
			changeBack(){
				this.narrowSel = false
				this.$router.go(-1)
			},
			mouseOverGame(ref,type){
				this[ref] = type
			},
			searchsGame(){
				if(this.searchVal == ''){
					return false
				}
				this.isFocus = false
				this.searchShow = false
				if(this.$route.name=='search'){
					this.$store.commit('saveKeyword',this.searchVal)
				}else{
					this.$router.push({ name: 'search',query:{keyword:this.searchVal}});
				}
				
			},
			changeToday(data){
				this.isFocus = false
				if(this.$route.name=='softwareDetails'){
					this.$store.commit('savePassId',data.slug)
				}else{
					this.$router.push({ name: 'softwareDetails',query:{id:data.slug}});
				}
				
			},
			erect(data){
				this.isFocus = false
				config.getDownload(data)
			},
			getFocus(type){
				if(this.searchShow){
					return false
				}
				window.win_in_search_box()
				this.isFocus = type
			},
			changeMenu(type){
				this.showMenu = type
			},
			changeTabs(index){
				if(this.focusTabIndex!=index){
					this.focusTabIndex=index
				}
			},
			changeDown(index,type){
				if(this.downloadListIndex!=index){
					this.downloadListIndex=index
					window.win_set_absolute_close(type)
					localStorage.setItem('downloadIndex',index)
				}
			},
			changeSel(ref,type){
				this[ref] = type
			},
			changeInst(index){
				if(this.instalListIndex!=index){
					this.instalListIndex=index
				}
			},
		}
	}
</script>

<style lang="scss">
	.recommend-main{
		background-color: #FFFFFF;
		padding-bottom: 36px;
		border-radius: 5px;
		// height: 500px;
	}
	.recommend-boom-item{
		padding-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		.recommend-install{
			width: 150px;
			height: 46px;
			text-align: center;
			line-height: 46px;
			background-color: #00B386;
			border-radius: 23px;
			color: #FFFFFF;
			font-size: 14px;
		}
	}
	.recommend-grid{
		margin-top: 20px;
		width: 100%;
		height: 110px;
		border-radius: 5px;
		background-color: #F1F8F5;
		text-align: center;
		position: relative;
		// padding-top: 20px;
		.recommend-list-img{
			width: 48px;
			height: 48px;
		}
		.recommend-list-name{
			text-align: center;
			padding-top: 8px;
			color: #333333;
			font-size: 12px;
			overflow: hidden; //超出的文本隐藏
			text-overflow: ellipsis; //溢出用省略号显示
			display: -webkit-box;
			-webkit-line-clamp: 1; // 超出多少行
			-webkit-box-orient: vertical;
		}
		.select-imgs{
			position: absolute;
			top: 4px;
			right: 4px;
			width: 16px;
			height: 16px;
		}
	}
	.grid-main{
		margin: 10px 50px 0px 50px;
	}
	.recommend-tel{
		text-align: center;
		padding-top: 8px;
		color: #333333;
		font-size: 24px;
		font-weight: bold;
	}
	.recommend-head{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 12px;
		padding-right: 12px;
		.recommend-head-text{
			color: #4D5571;
			font-size: 12px;
		}
		.recommend-head-img{
			width: 34px;
			height: 28px;
			margin-left: 14px;
		}
	}
	.search-result-tel{
		color: #333333;
		font-size: 14px;
		padding: 8px 0px;
	}
	.search-result-tel:hover{
		color: #00B386;
	}
	.search-result-item{
		display: flex;
		align-items: center;
		padding: 12px 0px 16px 0px;
		border-bottom: 1px solid #EEEEEE;
		.search-result-img{
			width: 40px;
			height: 40px;
			border-radius: 4px;
		}
		.search-result-info{
			margin-left: 14px;
			.search-result-name{
				color: #333333;
				font-size: 13px;
				width: 122px;
				overflow: hidden; 
				text-overflow: ellipsis; 
				display: -webkit-box;
				-webkit-line-clamp: 1; 
				-webkit-box-orient: vertical;
			}
			.search-result-name:hover{
				color: #00B386;
			}
			.search-result-num{
				margin-top: 5px;
				color: #999999;
				font-size: 12px;
			}
		}
		.search-result-btn{
			margin-left: auto;
			width: 56px;
			text-align: center;
			height: 28px;
			line-height: 28px;
			background-color: #00B386;
			border-radius: 14px;
			color: #FFFFFF;
			font-size: 12px;
		}
	}
	.search-result-main{
		position: absolute;
		left: 14px;
		top: 40px;
		width: 240px;
		border-radius: 4px;
		background-color: #FFFFFF;
		z-index: 999;
		box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.16);
		opacity: 1;
		// border: 1px solid #707070;
		// padding-bottom: 19px;
		height: 276px;
		padding: 9px 25px 19px 25px;
	}
	.empty-item{
		text-align: center;
		img{
			width: 200px;
			height: 200px;
		}
	}
	.download-content-ret{
		margin-left: auto;
		display: flex;
		align-items: center;
		img{
			width: 20px;
			height: 20px;
		}
	}
	.opens-btns{
		width: 56px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 14px;
		border: 1px solid #00B386;
		color: #00B386;
		font-size: 12px;
		margin-right: 2px;
	}
	.opens-btns:hover{
		background-color: #00B386;
		color: #FFFFFF;
	}
	.download-directory:hover{
		color: #00B386;
	}
	.download-directory{
		color: #4D5571;
		font-size: 12px;
		padding: 10px 14px;
	}
	.download-roll{
		height: 220px;
		overflow-y: auto;
	}
	.download-list{
		height: 64px;
		position: relative;
		margin-top: 1px;
		.download-schedule{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			background-color: #F2FBF7;
			width: 0%;
			z-index: 9;
		}
		.download-content{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 12px 15px;
			z-index: 20;
			display: flex;
			align-items: center;
			.download-content-img{
				width: 40px;
				height: 40px;
			}
			.download-content-info{
				margin-left: 8px;
				.download-content-name{
					color: #36383E;
					font-size: 14px;
				}
			}
			.download-content-text{
				margin-top: 7px;
				color: #999999;
				font-size: 12px;
				.speed{
					margin-left: 16px;
				}
			}
		}
	}
	.download-nav{
		display: flex;
		align-items: center;
		padding: 4px 6px 14px 14px;
		justify-content: space-between;
		.download-nav-tel{
			color: #000000;
			font-size: 13px;
			font-weight: bold;
		}
		.download-nav-ret{
			display: flex;
			align-items: center;
			color: #4D5571;
			font-size: 12px;
			.nav-ret-close{
				width: 34px;
				height: 28rpx;
				margin-left: 8rpx;
			}
		}
	}
	.download-manage{
		position: absolute;
		top: 37px;
		left: 0px;
		background: #FFFFFF;
		box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.16);
		border-radius: 4px 4px 4px 4px;
		width: 296px;
		// height: 296px;
	}
	.back-icon{
		width: 20px;
		height: 20px;
	}
	.no-click{
		pointer-events: none;
		cursor: not-allowed;
	}
	.search-clue-main{
		position: absolute;
		left: 0;
		top: 40px;
		width: 550px;
		border-radius: 5px;
		background-color: #FFFFFF;
		z-index: 999;
		box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.2);
		display: flex;
		padding-bottom: 14px;
		.search-clue-ret{
			width: 348px;
			background-color: #FBFBFB;
			padding: 16px 0px 0px 28px;
			.clue-ret-tels{
				color: #000000;
				font-size: 16px;
				font-weight: bold;
			}
			.clue-ret-list{
				display: flex;
				align-items: center;
				margin-top: 20px;
				.clue-ret-list-img{
					width: 40px;
					height: 40px;
				}
				.ret-list-infos{
					margin-left: 12px;
					.ret-list-name{
						color: #000000;
						font-size: 13px;
						
					}
					.ret-list-btns{
						margin-top: 11px;
						width: 48px;
						height: 24px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid #00B386;
						border-radius: 14px;
						color: #00B386;
						font-size: 12px;
					}
					.ret-list-btns:hover{
						background-color: #00B386;
						color: #FFFFFF;
					}
				}
			}
		}
		.search-clue-left{
			width: 202px;
			padding: 16px 20px 0px 26px;
			.search-clue-today{
				color: #FF6565;
				font-size: 16px;
				font-weight: bold;
			}
			.search-clue-list{
				display: flex;
				align-items: center;
				margin-top: 12px;
				.search-clue-nums{
					width: 15px;
					// padding: 1px 0px;
					text-align: center;
					border-radius: 2px;
					color: #FFFFFF;
					font-size: 12px;
					background-color: #58CFFF;
				}
				.ranking-rank-0{
					background-color: #FF7643!important;
				}
				.ranking-rank-1{
					background-color: #FFA101!important;
				}
				.ranking-rank-2{
					background-color: #FFC000!important;
				}
				.search-clue-tels:hover{
					color: #00B386;
				}
				.search-clue-tels{
					color: #4D5571;
					font-size: 14px;
					margin-left: 14px;
				}
				.search-clue-tags{
					margin-left: auto;
					width: 16px;
					height: 16px;
					line-height: 16px;
					text-align: center;
					border-radius: 2px;
					color: #FFFFFF;
					font-size: 11px;
					
				}
				.heat-tag{
					background-color: #FF6565;
				}
				.new-tag{
					background-color: #1AC964;
				}
			}
		}
	}
	.upgrade-items{
		margin-top: 20px;
		display: flex;
		.upgrade-item-info{
			margin-left: 8px;
			.upgrade-info-name{
				color: #1E2644;
				font-size: 13px;
			}
			.upgrade-info-text{
				color: #999999;
				font-size: 12px;
				margin-top: 5px;
			}
		}
	}
	.focus-sel-img{
		width: 15px;
		height: 15px;
		margin-top: 2px;
	}
	.focus-cards{
		display: flex;
		height: 450px;
		.focus-card-left{
			background-color: #F4F4F4;
			width: 120px;
			border-radius: 4px 0px 0px 4px;
			.focus-left-top{
				display: flex;
				align-items: center;
				padding: 12px 0px 0px 16px;
				.focus-left-logo{
					width: 14px;
					height: 15px;
				}
				.focus-left-name{
					margin-left: 7px;
					color: #1E2644;
					font-size: 14px;
				}
			}
		}
		.focus-boom-item{
			position: absolute;
			bottom: 20px;
			right: 22px;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.focus-boom-btn{
				width: 95px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid rgba(0,179,134,0.5);
				color: #FFFFFF;
				font-size: 14px;
				border-radius: 4px;
				background-color: #00B386;
			}
			.focus-boom-btn-close{
				background-color: #FFFFFF;
				color: #00B386;
				margin-left: 10px;
			}
		}
		.focus-card-ret{
			width: 540px;
			border-radius: 0px 4px 4px 0px;
			position: relative;
			background-color: #FFFFFF;
			.focus-ret-top{
				display: flex;
				justify-content: flex-end;
				padding-top: 10px;
				padding-right: 12px;
				.focus-ret-img{
					width: 34px;
					height: 28px;
				}
			}
			.focus-ret-main{
				padding: 16px 0px 0px 30px;
				.focus-ret-tel{
					color: #333333;
					font-size: 13px;
					font-weight: bold;
				}
				.download-tips{
					margin-top: 20px;
					color: #1E2644;
					font-size: 13px;
				}
				.download-open{
					margin-left: 14px;
					color: #00B386;
				}
				.download-items{
					display: flex;
					align-items: center;
					margin-top: 12px;
					.download-item-left{
						width: 293px;
						padding: 8px 10px;
						border: 1px solid #D8D8D8;
						border-radius: 3px;
						color: #999999;
						font-size: 12px;
					}
					.download-item-ret{
						margin-left: 10px;
						width: 95px;
						padding: 8px 0px;
						border: 1px solid #D8D8D8;
						border-radius: 3px;
						text-align: center;
						color: #00B386;
						font-size: 12px;
					}
				}
				.free-space{
					margin-top: 5px;
					color: #1E2644;
					font-size: 12px;
				}
			}
			.download-close-list{
				display: flex;
				align-items: center;
				margin-top: 20px;
				.download-set-img{
					width: 14px;
					height: 14px;
				}
				.download-set-text{
					margin-left: 8px;
					color: #1E2644;
					font-size: 13px;
				}
			}
		}
		.focus-tab-list{
			padding: 0px 7px;
			.focus-tab-item{
				margin-top: 20px;
				height: 29px;
				line-height: 29px;
				text-align: center;
				border-radius: 2px;
				color: #1E2644;
				font-size: 13px;
			}
			.focus-tabs{
				background-color: #00B386;
				color: #FFFFFF;
			}
		}
	}
	.el-dialog__body{
		padding: 0!important;
		border-radius: 4px!important;
	}
	 .el-dialog__header{
		padding: 0!important;
	}
	.fixed-header{
		position: fixed;
		top: 0;
		right: 0;
		z-index: 999;
		width: calc(100% - 170px);
		padding: 0;
		height: 74px;
		background-color: #FFFFFF;
		
	}
	.fixed-header-nav{
		display: flex;
		align-items: center;
		padding: 0px 20px;
		height: 74px;
	}
	.search-nav-item{
		display: flex;
		align-items: center;
		background-color: #F2F2F2;
		border-radius: 17px;
		height: 34px;
		width: 280px;
		padding-left: 24px;
		padding-right: 15px;
		margin-left: 10px;
		position: relative;
		.search-nav-tel{
			color: #4D5571;
			font-size: 14px;
			font-weight: bold;
			padding-bottom: 2px;
		}
		.search-nav-val{
			font-size: 14px;
			// margin-left: 8px;
			background-color: #F2F2F2;
			color: #000;
			height: 22px;
			line-height: 22px;
			width: 90%;
		}
		.search-nav-img{
			margin-left: auto;
			width: 14px;
			height: 14px;
		}
	}
	.download-nav-img{
		
		width: 34px;
		height: 34px;
	}
	.download-main{
		margin-left: 16px;
		position: relative;
	}
	.fixed-header-menu{
		margin-left: auto;
		position: relative;
		.header-menu-img{
			width: 40px;
			height: 28px;
		}
		.fixed-menu-list{
			position: absolute;
			width: 130px;
			background-color: #FFFFFF;
			box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
			border-radius: 5px;
			padding: 5px 0px;
			top: 32px;
			left: 0;
			// height: 100px;
			.fixed-menu-item:hover{
				background-color: #EEF0F6;
			}
			.fixed-menu-item{
				height: 32px;
				display: flex;
				align-items: center;
				padding-left: 16px;
				.fixed-menu-img{
					width: 14px;
					height: 14px;
				}
				.fixed-menu-text{
					color: #000000;
					font-size: 12px;
					margin-left: 10px;
				}
			}
		}
	}
</style>
