import request from '@/utils/request'

// 获取首页模块
export function homeList(data) {
  return request({
    url: '/api/module',
    method: 'get',
  })
}

// 分类列表
export function categoryList(type) {
  return request({
    url: `/api/category?type=${type}`,
    method: 'get',
  })
}

// 模块列表
export function moduleList(type) {
  return request({
    url: `/api/module?type=${type}`,
    method: 'get',
  })
}

// 模块下应用列表
export function moduleData(id) {
  return request({
    url: `/api/module-application?module_id=${id}`,
    method: 'get',
  })
}

// 软件详情
export function softwareDetails(id) {
  return request({
    url: `/api/application/${id}`,
    method: 'get',
  })
}

// 轮播图
export function bannerList(type) {
  return request({
    url: `/api/banner?type=${type}`,
    method: 'get',
  })
}

//根据分类id查询
export function categoryData(id,page,size,tagId,orderby) {
  return request({
    url: `/api/application/category/${id}?page=${page}&page_size=${size}&tag_id=${tagId}&orderby=${orderby}`,
    method: 'get',
  })
}

//搜索
export function searchFun(prm) {
  return request({
    url: `/api/application?${prm}`,
    method: 'get',
  })
}

// 获取下载链接
export function downloadUrl(id) {
  return request({
    url: `/api/application/${id}/download`,
    method: 'get',
  })
}

// 相关推荐
export function recommendData(id) {
  return request({
    url: `/api/application/${id}/recommend`,
    method: 'get',
  })
}